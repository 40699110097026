/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import { Radio } from 'src/components/Radio';
import {
  CUSTOM_CALENDAR_RANGE_OPTIONS,
  CUSTOM_CALENDAR_RANGE_VALUES_SET,
  LAST_X_DAYS_RANGE_OPTIONS,
  LAST_X_DAYS_RANGE_VALUES_SET,
  CALENDAR_RANGE_OPTIONS,
  CALENDAR_RANGE_VALUES_SET,
} from 'src/explore/components/controls/DateFilterControl/utils';
import {
  CustomCalendarRangeType,
  LastXDaysRangeType,
  CalendarRangeType,
  FrameComponentProps,
} from 'src/explore/components/controls/DateFilterControl/types';
import { Divider } from 'antd';
import { DEFAULT_TIME_RANGE } from '@superset-ui/core';

export function OptionsFrame(props: FrameComponentProps) {
  let commonRange = DEFAULT_TIME_RANGE;
  if (
    CUSTOM_CALENDAR_RANGE_VALUES_SET.has(props.value as CustomCalendarRangeType)
  ) {
    commonRange = props.value;
  } else if (
    LAST_X_DAYS_RANGE_VALUES_SET.has(props.value as LastXDaysRangeType)
  ) {
    commonRange = props.value;
  } else if (CALENDAR_RANGE_VALUES_SET.has(props.value as CalendarRangeType)) {
    commonRange = props.value;
  } else {
    props.onChange(commonRange);
  }

  return (
    <>
      <Radio.Group
        value={commonRange}
        onChange={(e: any) => props.onChange(e.target.value)}
      >
        {CUSTOM_CALENDAR_RANGE_OPTIONS.map(({ value, label }) => (
          <Radio key={value} value={value} className="vertical-radio">
            {label}
          </Radio>
        ))}
      </Radio.Group>

      <Divider />
      <Radio.Group
        value={commonRange}
        onChange={(e: any) => props.onChange(e.target.value)}
      >
        {LAST_X_DAYS_RANGE_OPTIONS.map(({ value, label }) => (
          <Radio key={value} value={value} className="vertical-radio">
            {label}
          </Radio>
        ))}
      </Radio.Group>

      <Divider />
      <Radio.Group
        value={commonRange}
        onChange={(e: any) => props.onChange(e.target.value)}
      >
        {CALENDAR_RANGE_OPTIONS.map(({ value, label }) => (
          <Radio key={value} value={value} className="vertical-radio">
            {label}
          </Radio>
        ))}
      </Radio.Group>
    </>
  );
}
