/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

// Linting Change made - undo in next commits

import moment, { Moment } from 'moment';
import {
  CustomRangeDecodeType,
  CustomRangeType,
  DateTimeModeType,
} from 'src/explore/components/controls/DateFilterControl/types';
import { SEPARATOR } from './dateFilterUtils';
import { SEVEN_DAYS_AGO, MIDNIGHT, MOMENT_FORMAT } from './constants';

/**
 * RegExp to test a string for a full ISO 8601 Date
 * Does not do any sort of date validation, only checks if the string is according to the ISO 8601 spec.
 *  YYYY-MM-DDThh:mm:ss
 *  YYYY-MM-DDThh:mm:ssTZD
 *  YYYY-MM-DDThh:mm:ss.sTZD
 * @see: https://www.w3.org/TR/NOTE-datetime
 */
const iso8601 = String.raw`\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(?:\.\d+)?(?:(?:[+-]\d\d:\d\d)|Z)?`;
const datetimeConstant = String.raw`(?:TODAY|NOW)`;
// const grainValue = String.raw`[+-]?[1-9][0-9]*`;
// const grain = String.raw`YEAR|QUARTER|MONTH|WEEK|DAY|HOUR|MINUTE|SECOND`;
// const CUSTOM_RANGE_EXPRESSION = RegExp(
//   String.raw`^DATEADD\(DATETIME\("(${iso8601}|${datetimeConstant})"\),\s(${grainValue}),\s(${grain})\)$`,
//   'i',
// );
export const ISO8601_AND_CONSTANT = RegExp(
  String.raw`^${iso8601}$|^${datetimeConstant}$`,
  'i',
);
const DATETIME_CONSTANT = ['now', 'today'];
export const defaultCustomRange: CustomRangeType = {
  sinceDatetime: SEVEN_DAYS_AGO,
  sinceMode: 'specific',
  sinceGrain: 'day',
  sinceGrainValue: 7,
  untilDatetime: MIDNIGHT,
  untilMode: 'specific',
  untilGrain: 'day',
  untilGrainValue: 0,
  anchorMode: 'now',
  anchorValue: 'now',
};
// const SPECIFIC_MODE = ['specific', 'today', 'now'];

export const dttmToMoment = (dttm: string): Moment => {
  if (dttm === 'now') {
    return moment().utc().startOf('second');
  }
  if (dttm === 'today') {
    return moment().utc().startOf('day');
  }
  return moment(dttm);
};

export const dttmToString = (dttm: string): string =>
  dttmToMoment(dttm).format(MOMENT_FORMAT);

export const customTimeRangeDecode = (
  timeRange: string,
): CustomRangeDecodeType => {
  const splitDateRange = timeRange.split(SEPARATOR);

  if (splitDateRange.length === 2) {
    const [since, until] = splitDateRange;

    // specific : specific
    if (ISO8601_AND_CONSTANT.test(since) && ISO8601_AND_CONSTANT.test(until)) {
      const sinceMode = (
        DATETIME_CONSTANT.includes(since) ? since : 'specific'
      ) as DateTimeModeType;
      const untilMode = (
        DATETIME_CONSTANT.includes(until) ? until : 'specific'
      ) as DateTimeModeType;
      return {
        customRange: {
          ...defaultCustomRange,
          sinceDatetime: since,
          untilDatetime: until,
          sinceMode,
          untilMode,
        },
        matchedFlag: true,
      };
    }
  }

  return {
    customRange: defaultCustomRange,
    matchedFlag: false,
  };
};

export const customTimeRangeEncode = (customRange: CustomRangeType): string => {
  const { sinceDatetime, sinceMode, untilDatetime, untilMode } = {
    ...customRange,
  };

  // specific : specific
  const since =
    sinceMode === 'specific' ? dttmToString(sinceDatetime) : sinceMode;
  const until =
    untilMode === 'specific' ? dttmToString(untilDatetime) : untilMode;

  return `${since} : ${until}`;
};
